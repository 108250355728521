import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import List from '../../components/Common/List'
import RequestDemo from '../../components/RequestDemo'
import ContactForm from '../../components/contact-form'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './digital-services.module.scss'

const DigitalServices: React.FC = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_digital_services')}
        description="Measure work accurately with digital methods like laser scanning, drones, and 360° photos. Select the right technique based on task, object features, accuracy, and timing."
        keywords="Precision laser scanning of buildings, Laser scanning for construction sites, Aerial surveying of the construction site, 360 photos on construction sites, Laser scanning, Stockpile measurements, Digital Elevation map, Aerial solutions, Orthophotomosaic, Drones in construction"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={t('banner_title_digital_services')}
        colorShadow="dark"
      />
      <Section className={s.section_description}>
        <DescriptionBlock2
          title={t('digital_services_subtitle')}
          description={t('digital_services_text')}
          classNameTitle="mb-4"
        />
      </Section>
      <ModuleSection2>
        <ModuleSectionItem2
          title={t('digital_services_subtitle2')}
          variantTitle="h2"
          description={t('digital_services_text2')}
          image={images.image.childImageSharp.gatsbyImageData}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/laser-scanning/'))}
          className="p-0"
          columnLeftClassName="flex justify-center"
          columnLeftChildren={
            <List className={s.list}>
              <li>{t('digital_services_list2.1')}</li>
              <li>{t('digital_services_list2.2')}</li>
              <li>{t('digital_services_list2.3')}</li>
            </List>
          }
        />
        <ModuleSectionItem2
          title={t('digital_services_subtitle3')}
          description={t('digital_services_text3')}
          variantTitle="h2"
          image={images.image2.childImageSharp.gatsbyImageData}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/aerial-drone-survey/'))}
          className={s.module_item}
          columnLeftChildren={
            <List className={s.list}>
              <li>{t('digital_services_list3.1')}</li>
              <li>{t('digital_services_list3.2')}</li>
              <li>{t('digital_services_list3.3')}</li>
            </List>
          }
          reverseElements
        />
        <ModuleSectionItem2
          title={t('digital_services_subtitle4')}
          description={t('digital_services_text4')}
          variantTitle="h2"
          image={images.image3.childImageSharp.gatsbyImageData}
          btnText={t('Read more')}
          onClick={() => navigate(getPath('/remote-inspection/'))}
          className="p-0"
          columnLeftChildren={
            <List className={s.list}>
              <li>{t('digital_services_list4.1')}</li>
              <li>{t('digital_services_list4.2')}</li>
              <li>{t('digital_services_list4.3')}</li>
            </List>
          }
        />
      </ModuleSection2>
      <RequestDemo
        title={t('Know more')}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default DigitalServices

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "digital-services/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image: file(relativePath: { eq: "digital-services/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "digital-services/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "digital-services/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
  }
`
